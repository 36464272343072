@import '../variables.css';

.type-link {
  fill: var(--link-color);
}

.type-link:hover {
  fill: var(--link-hover-color);
}

.type-name {
  &.-input-obj,
  &.-object {
    color: var(--link-color);

    &:hover {
      color: var(--link-hover-color);
    }
  }

  &.-scalar,
  &.-built-in {
    color: var(--builtin-color);

    &:hover {
      color: color(var(--builtin-color) l(- 10%));
    }
  }
}
