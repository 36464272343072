@import '../variables.css';

.description-box {
  & blockquote {
    border-left: 2px solid color(var(--secondary-color) a(50%));
    margin: var(--spacing-unit) calc(var(--spacing-unit) * 3);
    padding-left: calc(var(--spacing-unit) * 2);
  }

  & a {
    word-break: break-all;
  }

  & p:first-child {
    margin-top: 0;
  }

  &.-no-description {
    font-style: italic;
    color: var(--text-color);
  }

  &.-linked-type,
  &.-field,
  &.-enum-value {
    & p {
      margin: 0;
    }
  }

  &.-enum-value {
    padding: var(--spacing-unit) 0 0 var(--spacing-unit);
  }
}
