@import '../variables.css';

.eye-button {
  height: var(--icons-size);
  width: var(--icons-size);
  min-width: var(--icons-size);
  padding: 0;
  vertical-align: middle;

  & svg {
    line-height: var(--icons-size);
    height: var(--icons-size);

    & path:not([fill]) {
      fill: var(--primary-color);
    }
  }
}
